import React from 'react'
import SEO from '../components/seo'
import SubPageLayout from '../layouts/subPageLayout'
import { Section } from '../components/styled/section'
import styled from 'styled-components'

const Table = styled.table`
  text-align: left;
`

const SparePartsPage = () => {
  return (
    <SubPageLayout title="BilXtra integritetspolicy">
      <SEO title="BilXtra integritetspolicy" />
      <Section>
        <p>
          Hos BilXtra beskytter vi ditt personvern og etterstreber alltid et høyt
          databeskyttelsesnivå. I våre retningslinjer for personvern forklarer vi hvordan vi samler
          inn og bruker dine personopplysninger. Retningslinjene beskriver også dine rettigheter og
          hvordan du kan bruke dem.
        </p>
        <p>
          AS Sørensen og Balchen, organisasjonsnummer 916 591 144, med Rosenholmveien 12 1252 Oslo
          er behandlingsansvarlig for dine personopplysninger.
        </p>
        <p>
          BilXtra består av et stort antall butikker og verksteder hvor noen av enhetene er
          Egeneide, mens andre enheter er Samarbeidende. De samarbeidende butikkene og verkstedene
          er selv behandlingsansvarlig for sine personopplysninger. For å få tilgang til våre
          Samarbeidende butikker og verksteders retningslinjer, vennligst kontakt respektive butikk-
          eller verksted. Bruk vår søkefunksjon for å finne kontaktinformasjon for våre butikker og
          verksteder:{' '}
          <a href="www.bilxtra.no" target="_blank">
            www.bilxtra.no
          </a>
          .
        </p>
        <p>
          Dersom du har spørsmål angående vår håndtering av dine personopplysninger eller hvis du
          ønsker å utøve noen av dine rettigheter knyttet til håndtering av dine personopplysninger,
          vennligst kontakt vår databeskyttelsesansvarlig: gdpr@mekonomengroup.com
        </p>
        <p>
          Vår behandling av dine personopplysninger kan variere avhengig av kanalene eller
          tjenestene du velger å bruke. Nedenfor beskriver vi hvordan vi behandler dine personlige
          opplysninger dersom du er verkstedskunde, butikk kunde, e-handelskunde eller hvis du er
          medlem av vår medlemsklubb.
        </p>
        <h2>Hvilke personopplysninger behandler vi om deg</h2>
        <p>Personopplysninger du gir til oss eller som vi samler inn om deg:</p>
        <ul>
          <li>Navn: for- og etternavn</li>
          <li>Fødselsdato</li>
          <li>Kontaktinformasjon: adresse, e-postadresse, telefonnummer</li>
          <li>IP-adresse</li>
          <li>Bestillingsinformasjon: bestillingshistorikk, informasjon om bestilte varer</li>
          <li>
            Informasjon om dine kjøp samt informasjon om utført tjeneste: kjøpte varer og tjenester,
            beløp, innkjøpssted, dato og betalingsmåte, rabatter, informasjon om utført reparasjon
            og service
          </li>
          <li>Betalingsinformasjon: fakturainformasjon</li>
          <li>Kjøretøyinformasjon: bilregistreringsnummer, bilmodell</li>
          <li>
            Informasjon om ditt kjøretøy: kjørestrekning (kilometerstand), status på bilens
            komponenter og funksjoner
          </li>
          <li>
            Informasjon om reklamasjons-, og garantiordninger: informasjon om reklamasjonsforholdet,
            informasjon om produktgarantier, informasjon kjøpte varer, kundens korrespondanse.
          </li>
        </ul>
        <p>Personopplysninger vi mottar fra tredjeparter:</p>
        <ul>
          <li>
            Offentlige person- og kjøretøyregister: navn, kontaktinformasjon, kjøretøyinformasjon,
            tilstandsrapport, informasjon om eierskifte
          </li>
          <li>
            Firma som tilbyr betalingsløsninger (banker og andre betalingstjenester): For
            informasjon om betalingsgodkjenning.
          </li>
          <li>
            Firma i konsernet: navn, kontaktinformasjon, kjøretøyinformasjon for mottaker av
            informasjon vedrørende bestilt service eller reparasjon{' '}
          </li>
        </ul>
        <h3>For hvilke formål behandler vi dine personopplysninger som e-handelskunde</h3>
        <p>
          Vi behandler noe informasjon om deg, for eksempel fordi du har valgt å legge inn en
          bestilling hos oss. Personopplysningene vi samler inn om deg behandles for følgende formål
          og rettslig grunnlag:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Formål</th>
              <th>Behandling</th>
              <th>Kategori av personopplysninger</th>
              <th>Rettslig grunnlag</th>
              <th>Lagringstid</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>For å kunne administrere brukerkonto</td>
              <td>
                Tilgang til pålogging
                <br />
                Gjøre det mulig for deg å følge ordrehistorikken
              </td>
              <td>
                Navn
                <br />
                E- postadresse
              </td>
              <td>Oppfyllelse av avtale</td>
              <td>Inntil siste pålogging (aktivitet) og i en periode på 5 år deretter</td>
            </tr>
            <tr>
              <td>For å kunne tilby bruker et kjøretøytilpasset sortiment</td>
              <td>Innsamle kjøretøyinformasjon fra eksternt offentlig register</td>
              <td>Kjøretøyinformasjon</td>
              <td>Berettiget interesse</td>
              <td>Lagres ikke</td>
            </tr>
            <tr>
              <td>For å kunne håndtere dine bestillinger, kjøp og returer</td>
              <td>
                Levering, inkludert varsel og kommunikasjon angående leveransen.
                <br />
                Kommunikasjon vedrørende betalingsgodkjenning
                <br />
                Feilsøking ved eventuelle leveransefeil
                <br />
                Håndtering av reklamasjons- og garantisaker
              </td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Informasjon om kjøp
                <br />
                Godkjennelse av betaling
              </td>
              <td>Oppfyllelse av avtale</td>
              <td>Inntil levering og i en periode på 12 måneder deretter</td>
            </tr>
            <tr>
              <td>For å kunne håndtere forespørsler til kundeservice</td>
              <td>
                Kommunisere og besvare henvendelser til kundeservice via telefon eller digitalt
                <br />
                Behandle reklamasjoner og kundesupport
              </td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Korrespondanse med deg som kunde
                <br />
                Informasjon om reklamasjonssaker
              </td>
              <td>Berettiget interesse</td>
              <td>Inntil saken er avsluttet og i en periode på 12 måneder deretter</td>
            </tr>
            <tr>
              <td>For å kunne markedsføre produkter og tjenester</td>
              <td>Direkte markedsføring via digitale kanaler, e-post og SMS</td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Informasjon om kjøp
                <br />
                Kjøretøyinformasjon
              </td>
              <td>Berettiget interesse</td>
              <td>Fra innsamlet og i en periode på 12 måneder deretter</td>
            </tr>
            <tr>
              <td>For å påminne kunden om å sluttføre kjøpet</td>
              <td>Påminnelse om «forlatte» digitale handlekurver</td>
              <td>
                Kontaktinformasjon
                <br />
                Informasjon om påbegynte kjøp.
              </td>
              <td>Berettiget interesse</td>
              <td>Fra innsamlet og i en periode på 12 måneder deretter</td>
            </tr>
          </tbody>
        </Table>
        <h3>For hvilke formål behandler vi dine personopplysninger som butikkkunde?</h3>
        <p>
          Vi behandler noe informasjon om deg, for eksempel fordi du har valgt å legge inn en
          bestilling hos oss. Personopplysningene vi samler inn om deg behandles for følgende formål
          og rettslig grunnlag:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Formål</th>
              <th>Behandling</th>
              <th>Kategori av personopplysninger</th>
              <th>Rettslig grunnlag</th>
              <th>Lagringstid</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>For å tilby kunden et kjøretøytilpasset vareutvalg</td>
              <td>Innsamle kjøretøyinformasjon fra eksternt offentlig register</td>
              <td>Kjøretøyinformasjon</td>
              <td>Berettiget interesse</td>
              <td>Lagres ikke</td>
            </tr>
            <tr>
              <td>For å kunne håndtere bestillinger av reservedeler og tilbehør</td>
              <td>
                Administrere bestillingen
                <br />
                Kontakte kunden for informasjon om leveranse
              </td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Ordreinformasjon
              </td>
              <td>Oppfyllelse av kontrakten</td>
              <td>Inntil levering er mottatt i butikken og i en periode på 12 måneder deretter</td>
            </tr>
            <tr>
              <td>Motta retur-, klage- og garantiproblemer vedrørende et kjøpt produkt</td>
              <td>Motta returer, reklamasjoner og garantisaker</td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Informasjon om kjøp
              </td>
              <td>Rettslig forpliktelse (bokføringsloven)</td>
              <td>Inntil saken er avsluttet og i en periode på 5 år deretter (bokføringsloven)</td>
            </tr>
          </tbody>
        </Table>
        <h3>For hvilke formål behandler vi dine personopplysninger som medlem i vår kundeklubb</h3>
        <p>
          Vi behandler noe informasjon om deg, for eksempel fordi du har valgt å legge inn en
          bestilling hos oss. Personopplysningene vi samler inn om deg behandles for følgende formål
          og rettslig grunnlag:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Formål</th>
              <th>Behandling</th>
              <th>Kategori av personopplysninger</th>
              <th>Rettslig grunnlag</th>
              <th>Lagringstid</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                For å kunne administrere dine medlemsfordeler og tilbud etter vilkårene for
                medlemskapet
              </td>
              <td>
                For å sikre deg rettmessig rabatt ved kjøp som medlem i kundeklubben
                <br />
                Tilby personlige og generelle produkttilbud
                <br />
                Fremskape kundetilpassede nyheter og inspirasjon
              </td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Kjøretøyinformasjon
              </td>
              <td>Oppfyllelse av avtale</td>
              <td>Inntil medlemskapet avsluttes</td>
            </tr>
          </tbody>
        </Table>
        <h3>Hvem kan vi dele dine personopplysninger med?</h3>
        <h4>Databehandlere</h4>
        <p>
          I tilfeller der det er nødvendig for at vi skal kunne levere våre tjenester, deler vi dine
          personopplysninger med selskaper som bistår oss med behandling av personopplysninger. Et
          databehandlingsselskap er et selskap som behandler personopplysninger på våre vegne og
          basert på instruksjoner fra oss. Vi har inngått avtaler med selskapenes personvernombud
          for å sikre en riktig og sikker behandling av de personopplysningene de forplikter seg til
          å behandle. Våre databehandlere hjelper oss med:
        </p>
        <ul>
          <li>
            IT-tjenester (bedrifter som styrer drift, teknisk støtte og vedlikehold av våre
            IT-løsninger)
          </li>
          <li>
            kundeservice (andre selskaper i konsernet som håndterer kundeforespørsler mottatt via
            telefon eller online)
          </li>
          <li>markedsføring (sosiale medier, utskrifter og distribusjon)</li>
          <li>Firma som leverer teknisk informasjon</li>
          <li>Transportør (Logistikkfirma og speditør)</li>
          <li>Vareutlevering (samarbeidende butikker, eksterne aktører)</li>
          <li>Påminnelsestjenester (for påminnelse til kunden om å fullføre kjøpet)</li>
        </ul>
        <p>Selskaper som er selvstendig behandlingsansvarlig</p>
        <p>
          Vi deler også personopplysninger med firmaer som er selvstendig behandlingsansvarlige.
          Dette betyr at BilXtra/Sørensen og Balchen kontrollerer hvilke personopplysninger som blir
          overført til andre parter. Derimot er vi ikke ansvarlige for den påfølgende behandlingen
          når mottakeren tar over behandlingsansvaret når de mottar personopplysningene. Uavhengige
          behandlingsansvarlige vi deler personopplysninger med er:
        </p>
        <Table>
          <thead>
            <tr>
              <th>Formål</th>
              <th>Behandling</th>
              <th>Kategori av personopplysninger</th>
              <th>Rettslig grunnlag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Firmaer som tilbyr forsikringer, f.eks Viking redningstjeneste</td>
              <td>For å kunne tilby mobilitetsgaranti (forsikring) i sammenheng med service</td>
              <td>
                Kontaktinformasjon
                <br />
                Registreringsnummer
              </td>
              <td>Oppfyllelse av avtale</td>
            </tr>
            <tr>
              <td>Firmaer som tilbyr betalingsløsninger, f.eks. Klarna</td>
              <td>For å kunne tilby elektronisk betaling</td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
                <br />
                Betalingsinstruksjon
              </td>
              <td>Oppfyllelse av avtale</td>
            </tr>
            <tr>
              <td>Firma som besørger varetransport, f’eks Post Nord</td>
              <td>For å leverere varer til deg som kunde</td>
              <td>
                Navn
                <br />
                Kontaktinformasjon
              </td>
              <td>Oppfyllelse av avtale</td>
            </tr>
          </tbody>
        </Table>
        <h3>Innvending mot behandling av personopplysninger på grunnlag av berettiget interesse</h3>
        <p>
          Du har rett til å fremsette innvendinger mot behandling av dine personopplysninger som
          foretas på grunnlag av en berettiget interesse. Fortsatt behandling av dine
          personopplysninger krever i dette tilfellet at vi kan vise til tvingende legitime grunner
          som veier tyngre enn dine interesser, rettigheter og friheter, eller om det er gjort for å
          fastslå, utøve eller forsvare rettskrav.
        </p>
        <p>
          For at vi skal kunne håndtere kundeservicehenvendelser, må vi behandle din
          kontaktinformasjon, eventuell informasjon om klager og/eller annen korrespondanse. Vi har
          vurdert det slik at det foreligger et felles behov for behandling, og fordi ingen
          sensitive opplysninger er behandlet, har BilXtra/Sørensen og Balchen kommet til at behovet
          veier tyngre enn eventuell risiko for deg som registrert.
        </p>
        <p>
          Vi forsøker alltid å være så relevante som mulig i vår kommunikasjon med deg. For å sende
          relevante tilbud og påminnelser til deg som kunde, må vi behandle kontaktinformasjonen
          din, informasjon om dine kjøp, serviceinformasjon og informasjon om kjøretøyet ditt. Vi
          har gjort vurderingen at det også her er et felles behov, og at fordelene ved behandling
          veier tyngre enn din interesse om at det ikke behandles mer informasjon enn nødvendig.
        </p>
        <p>
          For å sikre at du som kunde bestiller/kjøper reservedeler og tilbehør som er skreddersydd
          til kjøretøyet ditt, må vi innhente registreringsnummer og kjøretøyinformasjon for å
          utføre produktsøk gjennom produktkatalogen vår. For at vi skal kunne bidra til sikkerheten
          din når du oppdager produktfeil i våre reservedeler, må vi behandle kontaktinformasjon,
          bestillingsinformasjon og registreringsnummer for å informere deg om et tilbakekallet
          produkt. Det er viktig for oss å sikre at vi tilbyr deg de riktige produktene, og siden vi
          ikke håndterer sensitiv informasjon, har vi vurdert det slik at det gjensidige behovet for
          denne behandlingen oppveier din interesse i at det ikke behandles mer informasjon enn
          nødvendig.
        </p>
        <h3>Hvor behandles personopplysningene dine?</h3>
        <p>
          Vårt mål er å behandle dine personopplysninger innen EU/EØS så langt som det er mulig.
          Dine personopplysninger kan imidlertid i noen situasjoner overføres til, og behandles av,
          mottakere i land utenfor EU/EØS. I slike tilfeller vil vi utføre alle rimelige juridiske,
          tekniske og organisatoriske tiltak for å sikre at personopplysningene behandles sikkert og
          med et tilstrekkelig beskyttelsesnivå som er sammenlignbart med, og på samme nivå som,
          beskyttelsen som tilbys i EU/EØS. Eksempler på passende beskyttelsesforanstaltninger er
          godkjent adferdskodeks i mottakerlandet, standardkontraktsklausuler, bindende
          selskapsinterne regler eller Privacy Shield. Hvis du vil ha en kopi av
          beskyttelsesforanstaltninger som vi har tatt eller informasjon om hvor disse er gjort
          tilgjengelige, kan du få dette ved å kontakte oss.
        </p>
        <p>
          For å håndtere kundeserviceproblemer (som inkluderer behandling av opplysninger som navn,
          kontaktinformasjon, kundens korrespondanse), bruker vi en samarbeidspartner med en
          underleverandør med servere i Texas. Organisasjonen er tilsluttet Privacy Shield, som er
          godkjent, og som inneholder et tilstrekkelig beskyttelsesnivå for personopplysninger.
        </p>
        <h3>Hvor lenge lagrer vi dine personopplysninger?</h3>
        <p>
          Vi lagrer dine personopplysninger så lenge det er nødvendig for hvert formål. Se mer om
          bestemte lagringsperioder under overskriften “For hvilke formål behandler vi dine
          personopplysninger?”.
        </p>
        <h3>Hvordan beskytter vi dine personopplysninger?</h3>
        <p>
          Vi utfører de juridiske, tekniske og organisatoriske sikkerhetsforanstaltninger som kreves
          for å sikre at personopplysninger ikke blir manipulert, tapt eller ødelagt eller at
          uautoriserte får tilgang til dem. Våre sikkerhetsrutiner blir oppdatert ettersom
          teknologien utvikler seg og forbedres.
        </p>
        <h3>Dine rettigheter</h3>
        <p>
          Vi ønsker å være åpne og transparente vedrørende hvordan vi behandler oppgavene dine. Hvis
          du ha innsyn i personopplysningsbehandlingen vi gjør i forhold til deg, har du rett til å
          be om informasjon om behandlingen.
        </p>
        <p>
          Hvis vi mottar en forespørsel om tilgang, kan vi be om ytterligere opplysninger for å
          sikre hvilke personopplysninger du vil ha innsyn i og for å gi personopplysningene til
          riktig person.
        </p>
        <h4>Du har rett til å:</h4>
        <ul>
          <li>
            motta bekreftelse fra oss hvis vi behandler personopplysninger om deg, og i så fall få
            tilgang til personopplysningene vi behandler om deg (registerutdrag) uten kostnad. Ved
            åpenbart ubegrunnede eller urimelige registreringsforespørsler (særlig på grunn av
            gjentatte forespørsler), kan en administrativ avgift bli belastet eller forespørselen
            nektes,
          </li>
          <li>korrigere feil eller ufullstendig personopplysninger,</li>
          <li>
            få dataene dine slettet i noen tilfeller, for eksempel hvis dine personopplysninger ikke
            lenger er nødvendige for det formål de ble samlet inn,
          </li>
          <li>
            begrense behandling av dine personopplysninger i noen tilfeller – f.eks. hvis du har
            protestert mot en behandling av dine personopplysninger som foretas etter en
            interesseavveining – mens kontroll gjøres om våre grunner veier tyngre enn dine
            interesser, rettigheter og friheter.
          </li>
          <li>
            hente og overføre personopplysninger til andre databehandlere i noen tilfeller
            (dataportabilitet). Retten til dataportabilitet dekker kun personopplysninger du har
            gitt oss og som vi behandler på grunnlag av visse rettsgrunnlag, for eksempel en avtale
            med deg.
          </li>
        </ul>
        <h3>Innvendinger mot behandling for direkte markedsføring</h3>
        <p>
          Du kan motsette deg at dine personopplysninger behandles for direkte markedsføring. Hvis
          du motsetter deg direkte markedsføring, vil vi avbryte behandlingen av dine
          personopplysninger for dette formålet, og avslutte enhver direkte markedsføringshandling.
          Du har naturligvis mulighet til å takke nei til e-post og personlige tilbud i enkelte
          kanaler, for eksempel kan du velge å bare motta tilbud fra oss via e-post, men ikke SMS.
        </p>
        <h3>Cookies og lignende</h3>
        <p>
          Vi bruker informasjonskapsler, web beacons og lignende teknikker (vanligvis kalt
          “cookies”) for å forbedre din opplevelse hos oss. Vi bruker cookies på vår nettside og i
          våre webtjenester. Her forklarer vi hvordan vi bruker cookies, og hvilke valg du kan gjøre
          når det gjelder våre cookies.
        </p>
        <h4>Hva er cookies?</h4>
        <p>
          Cookies er små tekstfiler bestående av bokstaver og tall. Disse sendes fra oss eller våre
          partneres webservere og lagres på din enhet som vist nedenfor. Vi benytter forskjellige
          cookies:
        </p>
        <ul>
          <li>
            Sessionscookies er en midlertidig informasjonskapsel som opphører når du lukker
            nettleseren eller appen din.
          </li>
          <li>
            Permanente cookies er informasjonskapsler som forblir på datamaskinen din til du sletter
            dem, eller de utløper.
          </li>
          <li>Førstepartscookies er informasjonskapsler angitt av nettstedet du besøker.</li>
          <li>Tredjepartscookies er informasjonskapsler angitt av en tredjepartsside.</li>
          <li>
            Web beacons er små, gjennomsiktige, grafiske bilder som kan finnes i e-post vi sender
            til deg.
          </li>
          <li>
            Lignende teknikker er teknikker som lagrer informasjon i nettleseren eller enheten din
            på en måte som ligner på informasjonskapsler og web beacons.
          </li>
        </ul>
        <h4>Hvorfor bruker vi cookies?</h4>
        <p>
          De cookies vi bruker forbedrer vanligvis de tjenestene vi tilbyr deg. Generelt
          kategoriserer vi våre informasjonskapsler og deres bruk som følger:
        </p>
        <ul>
          <li>
            Nødvendige cookies er absolutt nødvendige for å kunne tilby våre grunnleggende
            tjenester, for eksempel våre betalingsmåter eller vår kundeportal. Våre tjenester vil
            ikke fungere uten disse informasjonskapslene.
          </li>
          <li>Analyscookies gir samlet analytisk informasjon om bruken av tjenestene våre.</li>
          <li>
            Funksjonscookies gir oss mulighet til å lagre innstillinger som språkvalg, og om vi skal
            ferdigutfylle dine opplysninger eller ikke. Uten denne typen informasjonskapsler vil vi
            ikke kunne skreddersy våre tjenester i henhold til dine ønsker.
          </li>
          <li>
            Sikkerhetscookies gjør våre tjenester og dine personopplysninger trygge og sikre ved å
            hjelpe oss med å oppdage svindel og beskytte dine personopplysninger. Siden dette er en
            viktig del av tjenestene våre, er disse informasjonskapslene nødvendige.
          </li>
          <li>Reklamecookies gjør det mulig for oss å rette relevant markedsføring til deg.</li>
        </ul>
        <h4>Hvordan kan du kontrollere bruken av cookies?</h4>
        <p>
          Din nettleser eller enhet lar deg vanligvis endre innstillingene for bruk og omfang av
          cookies. Gå til innstillingene i nettleseren eller på enheten din for å lære deg mer om
          hvordan du justerer innstillingene for cookies. Du kan for eksempel velge å blokkere alle
          cookies, godta bare førstepartscookies eller slette cookies når du lukker nettleseren din.
        </p>
        <p>
          Vær oppmerksom på at noen av våre tjenester kanskje ikke fungerer hvis du blokkerer eller
          sletter cookies.
        </p>
        <h3>Klage til tilsynsmyndighetene</h3>
        <p>
          Hvis du mener at vi håndterer dine personopplysninger feil, kan du kontakte oss på
          følgende måte. Du har også rett til å sende inn en klage til tilsynsmyndigheten.
        </p>
        <h3>Hvordan kontakte oss?</h3>
        <p>
          Hvis du har spørsmål angående vår håndtering av dine personopplysninger eller hvis du
          ønsker å utøve noen av dine rettigheter knyttet til håndtering av dine personopplysninger,
          vennligst kontakt vår behandlingsansvarlig: gdpr@mekonomengroup.com
        </p>
        <p>Personvernretningslinjene ble sist oppdatert 23. april 2018</p>
      </Section>
    </SubPageLayout>
  )
}

export default SparePartsPage
